import React, { FC, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  ContentCopy as ContentCopyIcon,
  DeleteOutline as DeleteIcon,
} from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';

import ModalConfirmDialog from 'components/Modals/ModalConfirmDialog';
import Tooltip from 'components/Tooltip';
import { ATTACHMENT_FIELD, SIGNING_FIELD } from 'constants/editors';
import { setAllAssignments, setSelectFieldError } from 'store/actions/editorSlate';
import { deletePdfField, setSelectedFieldKey, updateFieldByKey } from 'store/actions/pdfTemplates';
import { RootStateType } from 'store/reducers';
import { PDFFieldType } from 'types/PdfTemplates';
import { getActualAssignmentsState, getMainTypeAssignment } from 'utils/assignmentsHelpers';
import { generateNumber } from 'utils/editorFieldHelpers';
import { createdNewField, focusNewField, getNewFieldsCoords, getPdfFieldSize } from 'utils/pdfTemplatesHelpers';

const BUTTONS_VERTICAL_OFFSET = 14; // 14 px

interface IPdfFieldsButtons {
  field: PDFFieldType;
  pdfFieldsCount: number;
  existingFieldMask: boolean;
  hideRevealFieldValueHandler: (fieldKey: number, newValue: boolean, stateType?: 'mask' | 'hide') => void;
  maskValuesState: Record<string, boolean>;
  isResizable: boolean;
}

const PdfFieldsButtons: FC<IPdfFieldsButtons> = ({
  field,
  pdfFieldsCount,
  existingFieldMask,
  hideRevealFieldValueHandler,
  maskValuesState,
  isResizable,
}) => {
  const dispatch = useDispatch();
  const { pdfTemplateFields } = useSelector((state: RootStateType) => state.pdfTemplates);
  const { assignments } = useSelector((state: RootStateType) => state.editorSlate);
  const [showConfirmDeletingModal, setShowConfirmDeletingModal] = useState<boolean>(false);

  const onCopyFieldHandler = () => {
    const newCoords = getNewFieldsCoords(field);

    const fieldKey = generateNumber();
    const copiedField: PDFFieldType = [SIGNING_FIELD, ATTACHMENT_FIELD].includes(field.type)
      ? createdNewField({
        type: field.type,
        position: pdfFieldsCount,
        coords: newCoords,
        pageNumber: field.pageNumber || 0,
        extendedField: { ...field, key: fieldKey },
      })
      : {
        ...field,
        key: fieldKey,
        coords: newCoords,
        position: pdfFieldsCount,
      };
    dispatch(updateFieldByKey(copiedField.key, copiedField));
    dispatch(setAllAssignments(getActualAssignmentsState(
      assignments,
      '',
      copiedField.assignment,
      fieldKey,
    )));
    // setTimeout is used because it's possible to focus this element only when it will be created in DOM tree
    setTimeout(() => {
      dispatch(setSelectedFieldKey(fieldKey));
      focusNewField(copiedField);
    }, 0);
  };

  const toggleModalWindow = () => {
    setShowConfirmDeletingModal(!showConfirmDeletingModal);
  };

  const deleteCurrentField = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    const availablePdfFields: string[] = Object.keys(pdfTemplateFields).filter((pdfFieldKey: string) => (
      pdfFieldKey !== String(field.key)
    ));

    dispatch(setAllAssignments(getActualAssignmentsState(
      assignments,
      field.assignment,
      '',
      field.key,
    )));

    setShowConfirmDeletingModal(false);
    dispatch(deletePdfField(field.key));
    dispatch(setSelectFieldError(false, field.key));
    dispatch(setSelectedFieldKey(Number(availablePdfFields[0]) || null));
  };

  const fieldSize = getPdfFieldSize(field.type, field.size);

  return (
    <div
      className={`pdf-field-buttons pdf-field-buttons-${getMainTypeAssignment(field.assignment)} ms-1`}
      style={{ left: fieldSize.width, top: Math.max(fieldSize.height / 2 - BUTTONS_VERTICAL_OFFSET, 0) }}
    >
      {
        isResizable && (
          <Tooltip title="Copy">
            <IconButton
              aria-label="Copy"
              size="small"
              className="p-1"
              color="inherit"
              onClick={onCopyFieldHandler}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )
      }
      {
        existingFieldMask && (
          <div style={!isResizable ? { position: 'absolute', right: '-25px' } : {}}>
            <Tooltip title="Hide/Reveal">
              <IconButton
                aria-label="Hide/Reveal"
                size="small"
                className="p-1"
                color="inherit"
                onClick={() => hideRevealFieldValueHandler(field.key, !maskValuesState?.hide, 'hide')}
              >
                {maskValuesState?.hide ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
          </div>
        )
      }
      {
        isResizable && (
          <>
            <Tooltip title="Delete">
              <IconButton
                aria-label="Delete"
                size="small"
                className="p-1"
                color="error"
                onClick={toggleModalWindow}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <ModalConfirmDialog
              showDialog={showConfirmDeletingModal}
              onClose={toggleModalWindow}
              onConfirm={deleteCurrentField}
            />
          </>
        )
      }
    </div>
  );
};

export default React.memo(PdfFieldsButtons);