import { FC } from 'react';

import DocumentNavigator from 'components/DocumentNavigator';
import EditorContainer from 'components/Editor/EditorContainer';
import PdfEditor from 'components/PdfEditor/PdfEditor';
import PdfFieldsGeneralWrapper from 'components/PdfEditor/PdfFieldsSidebar/PdfFieldsGeneralWrapper';
import SetSignersAndDocumentTitleHeader from 'components/SignNow/SetSignersAndDocumentTitleHeader';
import { useSetFields } from 'hooks/signNow';
import { IStepManagerChildComponentProps } from 'types/signNow/base';

import 'scss/components/Editor/_editor.scss';

const SetFieldsAndEditor: FC<IStepManagerChildComponentProps> = ({
  onNextHandler,
}) => {
  useSetFields({
    onNextHandler,
  });

  return (
    <>
      <SetSignersAndDocumentTitleHeader />
      <EditorContainer>
        <DocumentNavigator
          panelTitle=""
        >
          <PdfFieldsGeneralWrapper />
        </DocumentNavigator>
        <PdfEditor visibleEditorDevTools />
      </EditorContainer>
    </>
  );
};

export default SetFieldsAndEditor;