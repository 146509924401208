import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LensIcon from '@mui/icons-material/Lens';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Display, MUIDataTableColumnDef, MUIDataTableMeta } from 'mui-datatables';

import {
  MOMENT_DATE_FORMAT,
  MOMENT_MANAGEMENT_FIELD_FORMAT,
} from 'constants/dateField';
import {
  ACTIVE_USER_STATUS,
  COLLECTION_EXECUTED_TYPE,
  COLLECTION_TYPE,
  DISABLED_USER_STATUS,
  DOCUMENT_TYPE,
  FORM_TYPE,
  PDF_TYPE,
} from 'constants/general';
import ROUTES from 'constants/routes';
import { DocumentStatusType } from 'types/Documents';
import { GroupListRowsType, UserListRowsType } from 'types/Mui';
import { GroupType, UserListGetterType } from 'types/userProfile';
import { isEmptyObject, isNotEmptyObject } from 'utils/isEmptyObject';
import { getEditorPageLink, getEditorPageLinkForTable } from 'utils/routeHelpers';
import sortDocumentsByDate from 'utils/sortDocumentsByDate';

const COLUMN_ID = {
  name: 'id',
  options: {
    display: 'excluded' as Display,
    filter: false,
    sort: false,
    searchable: false,
  },
};
const COLUMN_CREATION_DATE = {
  name: 'created',
  label: 'Creation Date',
  options: {
    customBodyRender: (value: string) => (
      moment(value).format(MOMENT_DATE_FORMAT)
    ),
  },
};
const COLUMN_DOCUMENT_TYPE = {
  name: 'type',
  label: 'Type',
  options: {},
};
export const COLUMN_DOCUMENT_CATEGORY = {
  name: 'category',
  label: 'Category',
  options: {
    display: false,
  },
};
const COLUMN_NAME = {
  name: 'name',
  label: 'Document Name',
  options: {
    customBodyRender: (value: string, dataTable: MUIDataTableMeta) => {
      if (typeof value === 'string') {
        return <Link to={getEditorPageLinkForTable(dataTable.rowData)}>{value}</Link>;
      }
    },
  },
};
const COLUMN_COLLECTION_EXECUTED_NAME = {
  name: 'name',
  label: 'Collection Name',
  options: {
    customBodyRender: (value: { name: string, documents: any[] }, dataTable: MUIDataTableMeta) => (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="inherit">
            {
              typeof value.name === 'string'
                ? (<Link to={getEditorPageLinkForTable(dataTable.rowData)}>{value.name}</Link>)
                : <span>{value.name}</span>
            }
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {
              value.documents?.map((item) => {
                const { id, type, name, status } = item.document;
                return (
                  <div key={`collectionId_${item.collection_executed_id}_documentId_${id}`}>
                    <span className="status-icon" title={`${value}`}>
                      Status:
                      <LensIcon
                        className={`material-icons mx-1 mb-1 status-${status.toLowerCase().replaceAll(' ', '-')}`}
                      />
                      {status}
                    </span>
                    <li>
                      <Link to={getEditorPageLink(id, type, true)}>{name}</Link>
                    </li>
                  </div>
                );
              })
            }
          </ul>
        </AccordionDetails>
      </Accordion>
    ),
  },
};
const COLUMN_CREATOR = {
  name: 'creator',
  label: 'Creator Name',
  options: {},
};
const COLUMN_RECIPIENT_NAME = {
  name: 'recipient_name',
  label: 'Recipient Name',
  options: {},
};
const COLUMN_RECIPIENT_EMAIL = {
  name: 'recipient_email',
  label: 'Recipient Email',
  options: {
    display: false,
  },
};
const COLUMN_TEMPLATE_NAME = {
  name: 'template_name',
  label: 'Template Name',
  options: {
    display: false,
  },
};
const COLUMN_MODIFICATION_DATE = {
  name: 'updated',
  label: 'Modification Date',
  options: {
    display: false,
    customBodyRender: (value: string) => (
      moment(value).format(MOMENT_DATE_FORMAT)
    ),
  },
};
const COLUMN_MODIFIED_BY = {
  name: 'updater',
  label: 'Modified By',
  options: {
    display: false,
  },
};
export const COLUMN_STATUS = {
  name: 'status',
  label: 'Status',
  options: {
    customBodyRender: (value: string) => (
      <span className="status-icon" title={`${value}`}>
        <LensIcon className={`material-icons me-1 mb-1 status-${value.toLowerCase().replaceAll(' ', '-')}`} />
        {value}
      </span>
    ),
  },
};
export const COLUMN_EXECUTED = {
  name: 'executed',
  label: '',
  options: {
    display: 'excluded' as Display,
    filter: false,
    sort: false,
    searchable: false,
  },
};

export const stateColumn: MUIDataTableColumnDef[] = [{
  name: 'state',
  label: 'State',
  options: {
    display: false,
  },
}];

const COLUMN_FIRST_NAME = {
  name: 'first_name',
  label: 'First Name',
};

const COLUMN_LAST_NAME = {
  name: 'last_name',
  label: 'Last Name',
};

const COLUMN_EMAIL = {
  name: 'email',
  label: 'Email',
};

const COLUMN_USER_STATUS = {
  name: 'user_status',
  label: 'User Status',
};

const COLUMN_GROUP_CREATION_DATE = {
  name: 'creation_date',
  label: 'Creation Date',
  options: {
    customBodyRender: (value: string) => (
      moment(value).format(MOMENT_MANAGEMENT_FIELD_FORMAT)
    ),
  },
};

const COLUMN_GROUP_NAME = {
  name: 'group_name',
  label: 'Group name',
  options: {
    customBodyRender: (value: string, dataTable: MUIDataTableMeta) => {
      const groupId: string = dataTable.rowData[dataTable.rowData.length - 1].id;
      return (
        <Link to={`${ROUTES.GROUP_MANAGEMENT}/${groupId}`}>{value}</Link>
      );
    },
  },
};

const COLUMN_GROUP_MEMBERS = {
  name: 'group_members',
  label: 'Members',
};

const COLUMN_DOCUMENT_EXECUTED_UUID = {
  name: 'document_executed_uuid',
  label: 'Reference #',
  options: {
    display: false,
  },
};

// not used already, but planned to be repaired
export const sectionColumns: MUIDataTableColumnDef[] = [
  COLUMN_ID,
  {
    name: 'name',
    label: 'Section Name',
    options: {
      customBodyRender: (value, dataTable) => (
        <Link to={`${ROUTES.SECTIONS_EDITOR}/${dataTable.rowData[0]}`}>{value}</Link>
      ),
    },
  },
  COLUMN_CREATION_DATE,
  COLUMN_CREATOR,
  COLUMN_MODIFICATION_DATE,
  COLUMN_MODIFIED_BY,
];

export const dataColumns: MUIDataTableColumnDef[] = [
  COLUMN_ID,
  COLUMN_NAME,
  COLUMN_DOCUMENT_TYPE,
  COLUMN_DOCUMENT_CATEGORY,
  COLUMN_CREATION_DATE,
  COLUMN_CREATOR,
  COLUMN_MODIFICATION_DATE,
  COLUMN_MODIFIED_BY,
];

export const dataColumnsExecuted: MUIDataTableColumnDef[] = [
  COLUMN_ID,
  COLUMN_NAME,
  COLUMN_STATUS,
  COLUMN_EXECUTED,
  COLUMN_DOCUMENT_TYPE,
  COLUMN_DOCUMENT_CATEGORY,
  COLUMN_CREATION_DATE,
  COLUMN_RECIPIENT_NAME,
  COLUMN_RECIPIENT_EMAIL,
  COLUMN_MODIFICATION_DATE,
  COLUMN_MODIFIED_BY,
  COLUMN_TEMPLATE_NAME,
  COLUMN_DOCUMENT_EXECUTED_UUID,
];

export const dataColumnsCollectionExecuted: MUIDataTableColumnDef[] = [
  COLUMN_ID,
  COLUMN_COLLECTION_EXECUTED_NAME,
  COLUMN_STATUS,
  COLUMN_EXECUTED,
  COLUMN_DOCUMENT_TYPE,
  COLUMN_CREATION_DATE,
  COLUMN_RECIPIENT_NAME,
  COLUMN_RECIPIENT_EMAIL,
  COLUMN_MODIFICATION_DATE,
  COLUMN_MODIFIED_BY,
  COLUMN_TEMPLATE_NAME,
];

export const userManagementColumns: MUIDataTableColumnDef[] = [
  COLUMN_ID,
  COLUMN_CREATION_DATE,
  COLUMN_FIRST_NAME,
  COLUMN_LAST_NAME,
  COLUMN_EMAIL,
  COLUMN_USER_STATUS,
];

export const groupListingUserColumns: MUIDataTableColumnDef[] = [
  COLUMN_FIRST_NAME,
  COLUMN_LAST_NAME,
  COLUMN_EMAIL,
];

export const groupColumns: MUIDataTableColumnDef[] = [
  COLUMN_GROUP_CREATION_DATE,
  COLUMN_GROUP_NAME,
  COLUMN_GROUP_MEMBERS,
];

const generateLabel = (type: string) => (
  type[0].toUpperCase() + type.substring(1)
);

const createTypeLabel = (dataType: string) => {
  switch (dataType) {
    case DOCUMENT_TYPE:
    case FORM_TYPE:
    case COLLECTION_TYPE:
      return generateLabel(dataType);
    case COLLECTION_EXECUTED_TYPE:
      return 'Collection executed';
    default:
      return PDF_TYPE.toUpperCase();
  }
};

const filterTableData = (tableData: any[], type: string, statuses: DocumentStatusType) => {
  let copyData = [...tableData];
  if (type && type !== COLLECTION_EXECUTED_TYPE) {
    copyData = copyData.filter((item) => item.type === type);
  }
  if (statuses.length) {
    copyData = copyData.filter((item) => statuses.includes(item.status));
  }
  return copyData;
};

const getDocumentExecutedData = (isDocumentsExecutedTabs: boolean, docStatus: string) => (
  isDocumentsExecutedTabs ? [docStatus, COLUMN_EXECUTED.name] : []
);

export const createTableData = (
  data: any[],
  sortByType = '',
  sortByStatus: DocumentStatusType = [],
  pageType = '',
) => {
  const filteredData = filterTableData(data, sortByType, sortByStatus);
  const isDocumentsExecutedTabs = Boolean(sortByStatus.length);
  const isCollectionsExecuted = sortByType === COLLECTION_EXECUTED_TYPE;
  return filteredData.sort(sortDocumentsByDate).map((row: any) => {// eslint-disable-line
    if (isEmptyObject(row)) return [];
    const {
      id,
      name,
      type,
      status,
      doctype,
      creation_date: creationDate,
      creator,
      modification_date: modificationDate,
      last_modified_by: lastModifiedBy,
      documents: collectionDocuments,
      template,
      document_uuid: documentUUID,
    } = row;
    const dataType = isCollectionsExecuted ? COLLECTION_EXECUTED_TYPE : (type || COLLECTION_TYPE);
    const resultData = !isCollectionsExecuted
      ? [id.toString(), name]
      : [id.toString(), { name, documents: collectionDocuments }];
    const documentExecutedData = getDocumentExecutedData(isDocumentsExecutedTabs, status);
    const labelData = [createTypeLabel(dataType)];
    const categoryData = !isCollectionsExecuted ? [doctype?.name || ''] : [];
    const userName = creator && isNotEmptyObject(creator) ? `${creator.first_name} ${creator.last_name}` : '';
    const userEmail = creator && isNotEmptyObject(creator) ? creator.email : '';

    const lastPartOfData = [
      creationDate,
      userName,
      modificationDate,
      lastModifiedBy ? `${lastModifiedBy.first_name} ${lastModifiedBy.last_name}` : '',
    ];

    const kebabMenu = isDocumentsExecutedTabs
      ? [{ id: id.toString(), status, type: type || dataType }]
      : [{ id: id.toString(), type: dataType }];

    const templateName = template && !template.is_single ? template.name : 'None';
    if (isDocumentsExecutedTabs) {
      lastPartOfData.splice(2, 0, userEmail);
      lastPartOfData.push(isCollectionsExecuted ? name : templateName);
      if (!isCollectionsExecuted) {
        lastPartOfData.push(documentUUID);
      }
    }

    if (pageType === COLLECTION_TYPE) {
      return resultData.concat(
        documentExecutedData,
        labelData,
        lastPartOfData,
        kebabMenu,
      );
    }

    return resultData.concat(
      documentExecutedData,
      labelData,
      categoryData,
      lastPartOfData,
      kebabMenu,
    );
  });
};

export const getUserListData = (data: UserListGetterType[]): UserListRowsType => data
  .sort(sortDocumentsByDate)
  .map(({
    id,
    first_name: firstName,
    last_name: lastName,
    email,
    active,
    creation_date: creationDate,
  }: UserListGetterType) => {
    const status = active ? ACTIVE_USER_STATUS : DISABLED_USER_STATUS;
    const kebabMenu = [{ id: id.toString(), isUserActive: active }];
    return [
      id.toString(),
      creationDate,
      firstName,
      lastName,
      email,
      status,
      ...kebabMenu,
    ];
  });

export const transformGroupsToTableRows = (data: GroupType[] = []): GroupListRowsType => data
  .sort(sortDocumentsByDate)
  .map(({
    id: groupId,
    creation_date: creationDate,
    name: groupName,
    users_count: usersCount,
  }: GroupType) => {
    const kebabMenu = [{ id: groupId.toString() }];
    return [
      creationDate,
      groupName,
      usersCount,
      ...kebabMenu,
    ];
  });

export const transformGroupUsersToTableRows = (data: (UserListGetterType[])) => data.map(({
  first_name: firstName,
  last_name: lastName,
  email,
  id: userId,
  groupId,
}: UserListGetterType) => [
  firstName,
  lastName,
  email,
  { id: userId.toString(), groupId: groupId.toString() },
]);