/* eslint-disable prefer-regex-literals */
export const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9][A-Za-z0-9.+_-]+@[A-Za-z0-9._-]+\.[a-zA-Z]{2,}$/);
export const USER_NAMES_REGEX = new RegExp(/^(['"a-zA-Z-]+(?:['",.\s]+['",.a-zA-Z-]+)*$|^$)$/);
export const FILE_NAME_REGEX = new RegExp(/^[a-zA-Z0-9._\- ]+$/);
export const NUMBERS_REGEX = new RegExp(/^[0-9]+$/);
export const TEXT_WITH_SPACE_REGEX = (
  new RegExp(/^([`'"&a-zA-Z-]+(?:([-`'",.&\s]?)+[`'",.&a-zA-Z-]+)*$|^$)$/)
);
export const TEXT_AND_NUMBERS_WITH_SPACE_REGEX = (
  new RegExp(/^([`'"&a-zA-Z0-9-]+(?:([-`'",.&\s]?)+[`'",:.&a-zA-Z0-9-]+)*$|^$)$/)
);
export const WEBSITE_URL_REGEX = (
  new RegExp(/^(https?:\/\/)?(www\.)?[-a-zA-Z0-9:%._+~#=]{1,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*$)$/)
);
export const PHONE_NUMBER_REGEX = new RegExp(/^[+]?\d{2,}?[(]?\d{2,}[)]?[-\s.]?\d{2,}?[-\s.]?\d{3,}[-\s.]?\d{0,9}$/);
export const REFERRAL_CODE_REGEX = RegExp(/^[a-zA-Z0-9]*$/);

export const USER_EMAIL_MAX_LEN = 80;
export const USER_NAMES_MAX_LEN = 255;
export const TEXTAREA_MAX_LEN = 2000;
export const TITLE_MAX_LEN = 128;
export const SECTIONS_TITLE_MAX_LEN = 80;
export const PASSWORD_MAX_LENGTH = 128;
export const PASSWORD_MIN_LENGTH = 8;
export const GROUP_NAME_MAX_LEN = 80;
export const STRING_INPUT_MAX_LEN = 200;
export const ACCEPTED_ATTACHMENT_TYPES = [
  '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.jpg', '.jpeg',
  '.png', '.gif', '.txt', '.htm', '.html', '.odt', '.ods', '.heic', '.heif',
];

export const USER_REGISTRATION_TITLES = {
  username: 'Username',
  password: 'Password',
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email address',
};

export const CHANGE_PROFILE_TITLES = {
  first_name: 'First Name',
  last_name: 'Last Name',
  email: 'Email',
};

export const CHANGE_ORGANIZATION_TITLES = {
  name: 'Company Name',
  address: 'Address',
  postalCode: 'Postal Code',
  country: 'Country',
  state: 'State',
  city: 'City',
};

export const SENDGRID_SETTINGS_TITLES = {
  sendgridApiKey: 'SendGrid API Key',
  sendgridEmail: 'SendGrid Email',
};

export const enum SENDGRID_SETTINGS_FIELDS_NAMES {
  sendgridApiKey = 'sendgridApiKey',
  sendgridEmail = 'sendgridEmail'
}

export const SENDGRID_API_KEY_LENGTH = 69;

export const MIN_USERNAME_LENGTH = 2;
export const MAX_USERNAME_LENGTH = 80;

export const SEATS_LIMIT_NOTIFICATION = `Your account has the maximum number
  of seats, please contact us to increase usage.`;

export const REQUIRED_GROUP_NAME = 'Group name can\'t be empty';
export const OUT_OF_LIMIT_GROUP_NAME = `The Group name longer than maximum length ${GROUP_NAME_MAX_LEN}.`;
export const FORMAT_GROUP_NAME = 'Group name can\'t start or end with a blank space';

export const LOCALSTORAGE_NAME = 'localStorage';
export const DOCUMENT_COOKIE_NAME = 'document.cookie';