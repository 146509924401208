/* eslint-disable @typescript-eslint/no-explicit-any */
import { Descendant } from 'slate';

import { DOCUMENT_EXECUTED_TYPE, DOCUMENT_TYPE } from 'constants/general';
import PUBLIC_PAGE_STATUS from 'constants/publicPage';
import {
  ADD_PUBLIC_PAGE_ATTACHED_FILE_TO_STORE,
  CHECK_REMEMBERED_PII_DATA_IN_DATABASE,
  CLEAR_ATTACHMENTS,
  CREATE_DOCUMENT_PUBLIC_LINK,
  CREATE_MULTI_TEMPLATE_PUBLIC_LINK,
  CREATE_TEMPLATE_PUBLIC_LINK,
  DELETE_CURRENT_ID_FROM_DATA,
  DELETE_DOCUMENT_LINK_FROM_STORE,
  DELETE_MULTI_TEMPLATE_LINK_FROM_STORE,
  DELETE_PART_OF_HISTORY_LOG,
  DELETE_PUBLIC_PAGE_ATTACHED_FILE_FROM_STORE,
  DELETE_TEMPLATE_LINK_FROM_STORE,
  GET_PUBLIC_DOCUMENT,
  GET_PUBLIC_MULTI_TEMPLATE,
  GET_PUBLIC_PAGE_DATA,
  GET_PUBLIC_TEMPLATE,
  SAVE_PUBLIC_DOCUMENT,
  SAVE_TEMPORARY_USER_PII_DATA_IN_DATABASE,
  SEND_CODE_FOR_VERIFICATION,
  SET_ALL_DOCUMENTS_STATUS,
  SET_ATTACHMENTS_QUEUE_COMPLETED,
  SET_AUTOFILL_PII_DATA,
  SET_CREATED_DOCUMENT_FROM_PUBLIC,
  SET_CURRENT_DOCUMENT_TAB,
  SET_DISMISS_INTRODUCE_STEP,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENT_UUID,
  SET_GROUPED_FIELDS_STRUCTURE,
  SET_HISTORY_LOG_CURRENT_DOCUMENT,
  SET_MONDAY_COM_BOARD,
  SET_PII_DATA_MODAL_WAS_VISIBLE,
  SET_PUBLIC_PAGE_CURRENT_DOCUMENT,
  SET_PUBLIC_PAGE_CURRENT_SLIDE_INDEX,
  SET_PUBLIC_PAGE_DATA,
  SET_PUBLIC_PAGE_SENT_FILE_COUNTER,
  SET_PUBLIC_PAGE_START_LINK,
  SET_PUBLIC_PAGE_STRUCTURE,
  SET_PUBLIC_PAGE_TOTAL_INDEX_SLIDES,
  SET_SEND_LINK_FLOW,
  SET_SHOW_SMS_VALIDAION_MODAL,
  SET_SMS_VALIDAION_CODE_ERROR,
  SET_USER_SMS_CONSENT,
  UPDATE_GROUPED_FIELD,
  UPDATE_PUBLIC_PAGE_ATTACHMENT_PROPERTIES,
  UPDATE_PUBLIC_PAGE_DATA_SAGA,
  UPDATE_PUBLIC_PAGE_DOCUMENTS_SAGA,
  UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD,
  UPDATE_PUBLIC_PAGE_INTRO_ATTACHMENT_FIELD,
  UPDATE_PUBLIC_PAGE_INTRO_CURRENT_ATTACHMENT_FIELD,
  UPDATE_PUBLIC_PAGE_PII_DATA_IN_ALL_DOCUMENTS_SAGA,
} from 'store/actions/actionTypes';
import { HISTORY_ACTIONS } from 'types/DocumentsHistory';
import { ICustomElement } from 'types/Editor';
import {
  AggregatedFieldsStructureType,
  IPublicPageCurrentDocumentSetter,
  IPublicPageDataPayloadGetter,
  IPublicPageDataPayloadSetter,
  IPublicPageDocumentStructure,
  IPublicPageGeneralStructure,
  IPublicPageStructurePayload,
  PublicPageDataType,
} from 'types/PublicPage';
import { Action } from 'types/redux';
import { PIIDataType } from 'types/userProfile';

export const getPublicPageData = ({ url, type, id, salt, decodedAssignment }: IPublicPageDataPayloadGetter)
  : Action<IPublicPageDataPayloadGetter> => ({
  type: GET_PUBLIC_PAGE_DATA,
  payload: {
    url,
    type,
    id,
    salt,
    decodedAssignment,
  },
});

export const deleteCurrentIdFromData = () => ({
  type: DELETE_CURRENT_ID_FROM_DATA,
});

export const setPublicPageData = (data: PublicPageDataType | null): Action<IPublicPageDataPayloadSetter> => ({
  type: SET_PUBLIC_PAGE_DATA,
  payload: {
    data,
  },
});

export const updatePublicPageData = (
  content: Descendant[],
  id: number,
): Action<{ content: Descendant[], id: number }> => ({
  type: UPDATE_PUBLIC_PAGE_DATA_SAGA,
  payload: {
    content,
    id,
  },
});

export const setPublicPageStructure = (structure: IPublicPageGeneralStructure)
  : Action<IPublicPageStructurePayload> => ({
  type: SET_PUBLIC_PAGE_STRUCTURE,
  payload: {
    structure,
  },
});

export const setPublicPageCurrentDocument = (id: number | null): Action<IPublicPageCurrentDocumentSetter> => ({
  type: SET_PUBLIC_PAGE_CURRENT_DOCUMENT,
  payload: {
    id,
  },
});

export const setCurrentDocumentActiveTab = (id: number) => ({
  type: SET_CURRENT_DOCUMENT_TAB,
  payload: id,
});

export const updatePublicPageDocuments = (currentDocument: IPublicPageDocumentStructure)
  : Action<IPublicPageDocumentStructure> => ({
  type: UPDATE_PUBLIC_PAGE_DOCUMENTS_SAGA,
  payload: currentDocument,
});

export const setPublicPageCurrentSlideIndex = (index: number) => ({
  type: SET_PUBLIC_PAGE_CURRENT_SLIDE_INDEX,
  payload: index,
});

export const setPublicPageTotalIndexSlides = (index: number) => ({
  type: SET_PUBLIC_PAGE_TOTAL_INDEX_SLIDES,
  payload: index,
});

// Public Page Dropzone
export const updatePublicPageAttachmentProperties = (
  fieldKey: number,
  newProperties: {[key: string]: string | number},
  value: string,
) => ({
  type: UPDATE_PUBLIC_PAGE_ATTACHMENT_PROPERTIES,
  payload: {
    fieldKey,
    newProperties,
    value,
  },
});

export const addPublicPageAttachedFileToStore = (fieldID: number, file: any) => ({
  type: ADD_PUBLIC_PAGE_ATTACHED_FILE_TO_STORE,
  payload: {
    fieldID,
    file,
  },
});

export const deletePublicPageAttachedFileFromStore = (fieldID: number, file: any) => ({
  type: DELETE_PUBLIC_PAGE_ATTACHED_FILE_FROM_STORE,
  payload: {
    fieldID,
    file,
  },
});

export const setSentFilesCounter = () => ({
  type: SET_PUBLIC_PAGE_SENT_FILE_COUNTER,
});

export const updatePublicPageIntroAttachmentField = (documentId: number, fieldKey: number, value: string) => ({
  type: UPDATE_PUBLIC_PAGE_INTRO_ATTACHMENT_FIELD,
  payload: {
    documentId,
    fieldKey,
    value,
  },
});

export const updatePublicPageIntroCurrentAttachmentField = (documentId: number, fieldKey: number, value: string) => ({
  type: UPDATE_PUBLIC_PAGE_INTRO_CURRENT_ATTACHMENT_FIELD,
  payload: {
    documentId,
    fieldKey,
    value,
  },
});

export const saveTemporaryPIIUserDataInDatabase = (
  payload: PIIDataType[],
) => ({
  type: SAVE_TEMPORARY_USER_PII_DATA_IN_DATABASE,
  payload,
});

export const updatePublicPagePIIDataInAllDocuments = () => ({
  type: UPDATE_PUBLIC_PAGE_PII_DATA_IN_ALL_DOCUMENTS_SAGA,
});

export const checkRememberedPIIDataInDatabase = () => ({
  type: CHECK_REMEMBERED_PII_DATA_IN_DATABASE,
});

// Public templates
export const getPublicTemplate = (id: any) => ({
  type: GET_PUBLIC_TEMPLATE,
  payload: id,
});

export const createTemplatePublicLink = (body: any) => ({
  type: CREATE_TEMPLATE_PUBLIC_LINK,
  payload: body,
});

export const setCreatedDocumentId = (documentId: number) => ({
  type: SET_CREATED_DOCUMENT_FROM_PUBLIC,
  payload: documentId,
});

export const deleteTemplateLinkFromStore = () => ({
  type: DELETE_TEMPLATE_LINK_FROM_STORE,
});

// Public documents
export const getPublicDocument = (id: any) => ({
  type: GET_PUBLIC_DOCUMENT,
  payload: id,
});

export const createDocumentPublicLink = (body: any) => ({
  type: CREATE_DOCUMENT_PUBLIC_LINK,
  payload: body,
});

export const savePublicDocument = (document: any) => ({
  type: SAVE_PUBLIC_DOCUMENT,
  payload: document,
});

export const deleteDocumentLinkFromStore = () => ({
  type: DELETE_DOCUMENT_LINK_FROM_STORE,
});

// Public multi template
export const createMultiTemplatePublicLink = (body: any) => ({
  type: CREATE_MULTI_TEMPLATE_PUBLIC_LINK,
  payload: body,
});

export const getPublicMultiTemplate = (id: string) => ({
  type: GET_PUBLIC_MULTI_TEMPLATE,
  payload: id,
});

export const deleteMultiTemplateLinkFromStore = () => ({
  type: DELETE_MULTI_TEMPLATE_LINK_FROM_STORE,
});

export const setHistoryLogCurrentDocument = (logObject: { target: HISTORY_ACTIONS | string, value: string }) => ({
  type: SET_HISTORY_LOG_CURRENT_DOCUMENT,
  payload: logObject,
});

export const deletePartOfHistoryLog = (target: HISTORY_ACTIONS | string) => ({
  type: DELETE_PART_OF_HISTORY_LOG,
  payload: target,
});

export const clearAttachments = () => ({
  type: CLEAR_ATTACHMENTS,
});

export const setAttachmentsQueueCompleted = () => ({
  type: SET_ATTACHMENTS_QUEUE_COMPLETED,
});

export const setDocumentType = (type: typeof DOCUMENT_TYPE | typeof DOCUMENT_EXECUTED_TYPE | null) => ({
  type: SET_DOCUMENT_TYPE,
  payload: type,
});

// Public Page Form Builder
export const updateFormBuilderField = (
  keys: number[],
  value: Partial<ICustomElement> | string,
  additionalParameter: Record<string, boolean | string> = {},
) => ({
  type: UPDATE_PUBLIC_PAGE_FORM_BUILDER_FIELD,
  payload: {
    keys,
    value,
    additionalParameter,
  },
});

export const setIsSendLinkFlow = (isSendLinkFlow: boolean) => ({
  type: SET_SEND_LINK_FLOW,
  payload: isSendLinkFlow,
});

export const setDismissIntroduceModal = (dismiss: boolean) => ({
  type: SET_DISMISS_INTRODUCE_STEP,
  payload: dismiss,
});

export const setPublicPageStartLink = (startLink: string) => ({
  type: SET_PUBLIC_PAGE_START_LINK,
  payload: { startLink },
});

export const setAutoFillPIIData = (autoFillPIIData: boolean) => ({
  type: SET_AUTOFILL_PII_DATA,
  payload: { autoFillPIIData },
});

export const setPIIDataModalWasVisible = () => ({
  type: SET_PII_DATA_MODAL_WAS_VISIBLE,
});

export const setMondayComBoard = (board: string) => ({
  type: SET_MONDAY_COM_BOARD,
  payload: board,
});

export const setGroupedFieldsStructure = (updatedGroupedFieldsStructure: AggregatedFieldsStructureType[]) => ({
  type: SET_GROUPED_FIELDS_STRUCTURE,
  payload: updatedGroupedFieldsStructure,
});

export const updateGroupedField = (key: number, value: string) => ({
  type: UPDATE_GROUPED_FIELD,
  payload: {
    key,
    value,
  },
});

export const setAllDocumentsStatus = (status: PUBLIC_PAGE_STATUS) => ({
  type: SET_ALL_DOCUMENTS_STATUS,
  payload: status,
});

export const setUserSmsConsent = (smsConsent: boolean) => ({
  type: SET_USER_SMS_CONSENT,
  payload: smsConsent,
});

export const setShowSMSValidationModal = (
  showModal: boolean,
  phoneNumber: string,
  sendSMSUniqueCode: boolean = false,
) => ({
  type: SET_SHOW_SMS_VALIDAION_MODAL,
  payload: {
    showModal,
    phoneNumber,
    sendSMSUniqueCode,
  },
});

export const sendCodeForVerification = (code: string) => ({
  type: SEND_CODE_FOR_VERIFICATION,
  payload: code,
});

export const sendSMSValidationCodeError = (error: string) => ({
  type: SET_SMS_VALIDAION_CODE_ERROR,
  payload: error,
});

export const setDocumentUUID = (uuid: string | null) => ({
  type: SET_DOCUMENT_UUID,
  payload: uuid,
});