import { FC } from 'react';

import SignersContainer from 'components/SendDocuments/SignersContainer';
import SignerFlowComponent from 'components/SignNow/SignerFlow';
// import { EMPTY_SIGNER } from 'constants/sendDocuments';
import { SIGNER_FLOW_CONFIG, SignerFlow } from 'constants/signNow';
import useSignerFlowInSendFlow from 'hooks/SendFlow/useSignerFlowInSendFlow';
import { ISelectSigners } from 'types/SendDocuments';

const SelectDocumentSigners: FC<ISelectSigners> = ({
  signersArray,
  setSignersArray,
}) => {
  const {
    onlyCollection,
    signingOrderFlow,
    changeSignerFlowHandler,
  } = useSignerFlowInSendFlow();

  // const handlerAddButton = useCallback(() => {
  //   setSignersArray([...signersArray, { ...EMPTY_SIGNER, position: signersArray.length }]);
  // }, [setSignersArray, signersArray]);

  return (
    <div className="text-start">
      <div className="mt-3 select-signers-title">Please select and order signers</div>
      {
        !onlyCollection && (
          <div className="d-flex justify-content-between align-items-end flex-wrap gap-4">
            <SignerFlowComponent
              config={SIGNER_FLOW_CONFIG}
              onChange={changeSignerFlowHandler}
              currentSignerFlow={signingOrderFlow ?? SignerFlow.OrderedSigning}
            />
          </div>
        )
      }
      <SignersContainer
        signersArray={signersArray}
        setSignersArray={setSignersArray}
        isOrderVisible={signingOrderFlow === SignerFlow.OrderedSigning}
        canDeleteCard={signingOrderFlow !== SignerFlow.JustMe}
        isOnlyCollection={onlyCollection}
      />
      {/* TODO!!!  */}
      {/* {
        signingOrderFlow !== SignerFlow.JustMe && !onlyCollection && (
          <ButtonAddOutline onClick={handlerAddButton} title="Add signers" />
        )
      } */}
    </div>
  );
};

export default SelectDocumentSigners;