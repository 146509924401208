/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEventHandler, FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { FormFeedback, Input } from 'reactstrap';

import { Autocomplete, OutlinedInput, styled, TextField, TextFieldProps } from '@mui/material';
import { Node, Transforms } from 'slate';
import { ReactEditor, useSlate as getSlateEditor } from 'slate-react';

import cn from 'classnames';
import AttachmentDropzone from 'components/AttachmentDropzone';
import AttachmentsList from 'components/AttachmentsListComponents/AttachmentsList';
import DateCalendar from 'components/Base/DateCalendar';
import OtherOptionElement from 'components/Editor/components/Fields/OtherOptionElement';
import RadioSelectElement from 'components/Editor/components/Fields/RadioSelectElement';
import FieldName from 'components/Editor/FormView/Fields/FieldName';
import FormFieldButtons from 'components/Editor/FormView/Fields/FormFieldButtons';
import ShowSignatureValue from 'components/Modals/SignatureModal/ShowSignatureValue';
import SigningModal from 'components/Modals/SignatureModal/SigningModal';
import {
  ATTACHMENT_FIELD,
  DATE_FIELD,
  FORM_TEXTAREA_ROWS_COUNT,
  QUESTION_ANSWER_STRING,
  QUESTION_FIELD,
  SELECT_FIELD,
  SIGNING_FIELD,
  TEXT_FIELD,
  TEXTAREA_FIELD,
} from 'constants/editors';
import { TEXT_FIELD_MASKS } from 'constants/fieldPropertiesTab';
import { FORM_TYPE, SIGNED_BY } from 'constants/general';
import { SIGNATURE_TABS } from 'constants/signatures';
import { TEXTAREA_MAX_LEN } from 'constants/validation';
import { useAssignmentFieldColor } from 'hooks/signNow';
import useFieldMaskValue from 'hooks/useFieldMaskValue';
import useTrackingLogEvents from 'hooks/useTrackingLogEvents';
import { updatePublicPageIntroCurrentAttachmentField } from 'store/actions/publicPages';
import { RootStateType } from 'store/reducers';
import SignatureIcon from 'svg/SignatureIcon';
import {
  FieldViewPropsChangeValueHandler,
  FormSelectUpdateEvent,
  ICustomElement,
  SelectFieldOptionsType,
  SelectFieldOptionType,
} from 'types/Editor';
import { SignerType } from 'types/SendDocuments';
import { isManagerAssignment } from 'utils/assignmentsHelpers';
import {
  getCurrentDate,
  getSelectedFieldClass,
  isFindField,
  setSelectedFieldState,
  updateSelectFieldOptionsHandler,
} from 'utils/editorFieldHelpers';
import { getDefaultDateMask } from 'utils/editorHelpers';
import { getSelectValuesToUpdate } from 'utils/editorSelectFieldHelpers';
import { getIsRadioOrCheckbox } from 'utils/Fields/checkboxRadioHelpers';
import updateValueWithMaxLength from 'utils/Fields/updateValueWithMaxLength';
import { onFieldCustomKeyDown, onSelectFieldKeyDown } from 'utils/hotkeysHelpers';
import getFieldName from 'utils/PublicPage/getFieldName';
import { getSignatureValueAndActiveTab } from 'utils/userPersonalData/personalDataHelpers';

import vars from 'variables.module.scss';

export interface IFieldViewProps {
  field: Partial<ICustomElement>;
  options?: SelectFieldOptionType[];
  onChangeValue: FieldViewPropsChangeValueHandler;
  onChangeAttachmentField?: (field: Partial<ICustomElement>) => void;
  readOnlyMode: boolean;
  validateField?: boolean;
  validationMessage?: string;
  showButtons?: boolean;
  styledLabelColors?: boolean;
}

export const StyledInputField = styled(OutlinedInput)({
  '& input': {
    padding: '12px 14px',
  },
  '& fieldset': {
    top: 0,
    '& legend': {
      display: 'none',
    },
  },
  '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: vars.brandPrimaryFocused,
    borderWidth: '1px',
  },
});

export const StyledTextField = styled(TextField)({
  '& .MuiAutocomplete-inputRoot input.MuiAutocomplete-input': {
    padding: '3px 4px',
  },
  '& fieldset': {
    top: 0,
    '& legend': {
      display: 'none',
    },
  },
  '& .Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    borderColor: vars.brandPrimaryFocused,
    borderWidth: '1px',
  },
});

const dateInputComponent = ({ InputProps, inputProps, inputRef, label }: TextFieldProps) => {
  const inputParams = {
    inputProps: { ...inputProps, className: 'editor-field-view-input' },
    ref: inputRef,
    label,
    ...InputProps,
  };
  return (
    <StyledInputField
      {...inputParams}
      autoComplete="off"
      size="small"
      fullWidth
      required={false}
      error={false}
      className="editor-field-view-wrapper"
    />
  );
};

const EditorFieldView: FC<IFieldViewProps> = ({
  field,
  options,
  onChangeValue,
  onChangeAttachmentField = () => null,
  readOnlyMode,
  validateField = false,
  validationMessage = '',
  showButtons = false,
  styledLabelColors = false,
}) => {
  /**
   * The Form view is used for only Slate Forms for manager interface
   * TODO: we can use validateField property for manager interface validation in future
   * TODO: refactor for old sections in src\components\Editor\FormView\index.tsx
   */
  const dispatch = useDispatch();
  const callSigningTrackingEvents = useTrackingLogEvents(Number(field.key), field.assignment);

  const { selectedField } = useSelector((state: RootStateType) => state.editorSlate);
  const { documentDetails, templateDetails } = useSelector((state: RootStateType) => state.user);
  const { attachments } = useSelector((state: RootStateType) => state.publicPages);
  const {
    save_signature: saveSignature,
    signature_path: signaturePath,
  } = useSelector((state: RootStateType) => state.profile?.personalData ?? {});

  const [fieldValue, setFieldValue] = useState<string>('');
  const [signatureHash, setSignatureHash] = useState<string>('');
  const [otherOptionValue, setOtherOptionValue] = useState<string>('');

  const [showSigningModal, setShowSigningModal] = useState<boolean>(false);
  const [isFieldFirstClick, setIsFieldFirstClick] = useState<boolean>(true);
  const [signatureTab, setSignatureTab] = useState<SIGNATURE_TABS>(field.signatureTab ?? SIGNATURE_TABS.DRAW_TAB);

  const existingFieldMask = Boolean(field.textFieldMask) && field.textFieldMask !== 'none';
  const [hideValue, setHideValue] = useState<boolean>(existingFieldMask);

  const fieldKey = field.key || 0;
  const isRequired = (validateField && field.requiredField) || false;
  const showErrorMessage = (validateField && validationMessage.length > 0) || false;
  const helpText = (validateField && field.helpText) || '';
  const name = getFieldName(field, false);

  const editor = getSlateEditor();

  useEffect(() => {
    if (field.value !== undefined && field.value !== fieldValue) {
      setFieldValue(field.value);
    }
    if (field.selectedOtherOption && otherOptionValue !== field.value) {
      setOtherOptionValue(field.value ?? '');
    }
  }, [field.value, field.selectedOtherOption]);

  useEffect(() => {
    if (validateField && field.isTodaysDate && editor) {
      editor.findNodesAndUpdate((node) => {
        if (!isFindField(node, fieldKey)) {
          return node;
        }
        const updatedIsTodaysDate = {
          ...field,
          isTodaysDate: false,
          value: getCurrentDate(),
        };
        return { ...node, ...updatedIsTodaysDate };
      });
    }
  }, [editor, field, fieldKey, validateField]);

  useEffect(() => {
    const tabToShow = field.signatureTab ?? SIGNATURE_TABS.DRAW_TAB;
    if (tabToShow !== signatureTab) {
      setSignatureTab(tabToShow);
    }
  }, [field.signatureTab]);

  const handlerChangeValue = (event: any) => {
    if (event.preventDefault) event.preventDefault();
    let value = event.target.value;
    if (field.type === TEXT_FIELD) {
      value = updateValueWithMaxLength(value, field.maxLength);
      // eslint-disable-next-line no-param-reassign
      event.target.value = value;
    }
    setFieldValue(value);
    onChangeValue(event, fieldKey);
  };

  const onChangeSelectHandler = (event: FormSelectUpdateEvent, eventValue?: string | null) => {
    if (readOnlyMode) return;

    const optionProps: DOMStringMap = {};
    const target = event.target as HTMLSelectElement & HTMLInputElement;
    const isEmptyDataset = !target.dataset?.id;

    if (isEmptyDataset && eventValue && eventValue.length > 0) {
      const selectedOption = options?.find((option) => option.label === eventValue);
      if (selectedOption) {
        const optionIndex = options?.findIndex((option) => option.id === selectedOption?.id);
        optionProps.id = String(selectedOption?.id) || undefined;
        optionProps.optionIndex = String(optionIndex) || undefined;
      }
    }

    const { id, optionIndex } = isEmptyDataset ? optionProps : target.dataset;

    const { updatedOptions, value } = updateSelectFieldOptionsHandler({
      options: field.options || [],
      viewMode: field.selectFieldType,
      id: Number(id),
      selectedIndex: Number(optionIndex) + 1,
    });

    const previousValue = field.selectedOtherOption ? otherOptionValue : '';
    const updatedProperties = getSelectValuesToUpdate({
      fieldValue: value,
      previousValue,
      updatedOptions,
      fieldView: field.selectFieldType,
    });

    onChangeValue(updatedProperties, fieldKey);
  };

  const onChangeHandlerOtherOption: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setOtherOptionValue(value);
    onChangeValue({ value }, fieldKey);
  };

  const handlerChangeDate = (value: string) => {
    if (!readOnlyMode) {
      setFieldValue(value);
      const path = ReactEditor.findPath(editor, field as ICustomElement);
      Transforms.setNodes(editor, { value }, { at: path });
      onChangeValue({ target: { value } }, fieldKey);
    }
  };

  const handleSignatureSave = (base64String: string, currentTab: SIGNATURE_TABS, signatureHash: string = '') => {
    if (!readOnlyMode) {
      setSignatureTab(currentTab);
      setFieldValue(base64String);
      onChangeValue(base64String, fieldKey, false, currentTab);
      if (signatureHash) {
        setSignatureHash(signatureHash);
      }
    }
  };

  const onTrimFieldValue = (event: any) => {
    setFieldValue(event.target.value);
    onChangeValue(event, fieldKey, true);
  };

  const clickFieldHandler = () => {
    if (!validateField) {
      setSelectedFieldState(selectedField, fieldKey, dispatch);
    }
  };

  const onOpenSignModal = async () => {
    if (!readOnlyMode) {
      if (saveSignature && signaturePath && isFieldFirstClick && isManagerAssignment(field.assignment)) {
        setIsFieldFirstClick(false);
        const { signatureValue, signatureHash, tabToShow } = await getSignatureValueAndActiveTab();
        handleSignatureSave(signatureValue, tabToShow, signatureHash);
        callSigningTrackingEvents();
      } else {
        setIsFieldFirstClick(true);
        setShowSigningModal(true);
      }
    }
  };

  const generalFieldParameters = {
    name: 'value',
    required: isRequired,
    invalid: String(showErrorMessage),
    placeholder: helpText,
    readOnly: readOnlyMode,
    disabled: readOnlyMode,
    label: '',
    fullWidth: true,
    tabIndex: -1,
  };

  const handleSelectFieldKeyDown = (event: React.KeyboardEvent) => {
    onSelectFieldKeyDown(event, onChangeSelectHandler, options);
  };

  const createSelectField = () => (
    <>
      <Autocomplete
        className="select-field-autocomplete editor-field-view-wrapper"
        disablePortal
        options={[''].concat((options || []).map((option) => option.label))}
        getOptionLabel={(option) => option || ''}
        blurOnSelect
        isOptionEqualToValue={(option, value) => (
          option === value as string
        )}
        value={
          field.selectedOtherOption
            ? field.options?.find((option) => option.isSelectOtherOption)?.label
            : fieldValue
        }
        onChange={(event, value) => onChangeSelectHandler(event, value)}
        noOptionsText=""
        {...generalFieldParameters}
        tabIndex={-1}
        renderInput={(params) => {
          const additionalParams = {
            ...params,
            inputProps: {
              ...params.inputProps,
              onKeyDown: handleSelectFieldKeyDown,
            },
            tabIndex: -1,
          };
          return (
            <StyledTextField
              {...additionalParams}
              className={`${(!fieldValue) ? 'empty-value' : ''}`}
            />
          );
        }}
      />
      {
        field.selectedOtherOption && (
          <OtherOptionElement
            name={field.name}
            value={otherOptionValue}
            onChangeHandler={onChangeHandlerOtherOption}
            simpleInput={false}
            addMargin
            disabled={readOnlyMode}
          />
        )
      }
    </>
  );

  const createSelectRadioField = (selectFieldType: SelectFieldOptionsType) => (
    <div className="form-radio-select editor-field-view-wrapper">
      <RadioSelectElement
        element={field}
        viewMode={selectFieldType}
        onChangeRadioOption={onChangeSelectHandler}
        isReadOnlyMode={readOnlyMode}
        className="form editor-field-view-wrapper"
      />
      {
        field.selectedOtherOption && (
          <OtherOptionElement
            name={field.name}
            value={otherOptionValue}
            onChangeHandler={onChangeHandlerOtherOption}
            simpleInput={false}
            disabled={readOnlyMode}
          />
        )
      }
    </div>
  );

  const { hiddenValue, onChangeValueWithMask } = useFieldMaskValue({
    fieldMaskType: field.textFieldMask,
    hideValue,
    realValue: fieldValue,
    onChangeRealValue: handlerChangeValue,
  });

  const renderTextField = () => (
    <StyledInputField
      type="text"
      value={!hideValue ? fieldValue : hiddenValue}
      onChange={!hideValue ? handlerChangeValue : onChangeValueWithMask}
      {...generalFieldParameters}
      className="editor-field-view-wrapper"
      inputProps={{
        className: 'editor-field-view-input',
      }}
    />
  );

  const renderDateField = () => (
    <DateCalendar
      fieldValue={fieldValue}
      dateMask={getDefaultDateMask(field.dateMask)}
      isReadOnlyMode={readOnlyMode}
      onChangeDateValue={handlerChangeDate}
      showAdornment
      inputComponent={dateInputComponent}
      {...generalFieldParameters}
    />
  );

  const renderQuestionField = () => (
    field.answerType === QUESTION_ANSWER_STRING
      ? renderTextField()
      : createSelectField()
  );

  const renderSigningField = () => {
    const fieldContent = !fieldValue
      ? (
        <>
          <span className="signature-icon my-3"><SignatureIcon /></span>
          <span className="field-help-text signature-help-text m-2">Please sign here</span>
        </>
      )
      : (
        <ShowSignatureValue
          value={field.value}
        />
      );
    const variativeClasses = fieldValue
      ? 'signature-field-button button signature-field-button-public'
      : 'p-2 align-items-center justify-content-center';
    const defaultValueParam = fieldValue ? {} : { defaultValue: fieldValue };

    return (
      <div className="w-100 text-center signature-field-wrapper">
        <div
          className="signature-field"
          data-empty={Boolean(!fieldValue)}
          onClick={readOnlyMode ? undefined : onOpenSignModal}
          role="button"
          tabIndex={readOnlyMode ? -1 : 0}
          onKeyDown={(event) => onFieldCustomKeyDown(event, readOnlyMode ? undefined : onOpenSignModal)}
        >
          <div className="title-signed-by">{SIGNED_BY}</div>
          <div className={`w-100 d-flex ${variativeClasses}`}>
            {fieldContent}
          </div>
          {
            fieldValue && (field.starting_hash || signatureHash) && (
              <div className="text-center signature-hash">
                {field.starting_hash || signatureHash}...
              </div>
            )
          }
          <Input
            type="text"
            name="value"
            autoComplete="off"
            className="d-none py-4 signature-field"
            {...defaultValueParam}
          />
        </div>
      </div>
    );
  };

  const renderTextArea = () => (
    <StyledInputField
      multiline
      type="textarea"
      value={fieldValue}
      error={showErrorMessage}
      onBlur={onTrimFieldValue}
      onChange={handlerChangeValue}
      rows={FORM_TEXTAREA_ROWS_COUNT}
      inputProps={{ maxLength: TEXTAREA_MAX_LEN }}
      className="editor-field-view-wrapper"
      {...generalFieldParameters}
    />
  );

  const handlerChangeAttachmentCount = (currentCount: number, attachedField?: string[]) => {
    if (validateField && field.key) {
      const updatedAttachmentLimit = {
        properties: {
          ...field.properties,
          count: currentCount,
        },
        value: attachedField !== undefined ? attachedField?.join() : field.value,
      };
      onChangeAttachmentField(updatedAttachmentLimit as Partial<ICustomElement>);
    }
  };

  const handlerUpdateAttachmentValueAndCount = (newValueArray: string[]) => {
    if (!field.key || (!documentDetails?.id && !templateDetails?.id)) return;
    const updatedAttachmentField = {
      ...field,
      value: newValueArray.join(','),
      properties: {
        ...field.properties,
        count: newValueArray.length,
      },
    };

    if (attachments && attachments[fieldKey]) {
      dispatch(updatePublicPageIntroCurrentAttachmentField(
        documentDetails.id || templateDetails.id,
        field.key,
        newValueArray.join(),
      ));
    }
    onChangeAttachmentField(updatedAttachmentField as Partial<ICustomElement>);
  };

  const renderAttachmentField = () => (
    <>
      <Input
        className={`d-none attachments_${fieldKey}`}
        type="text"
        name="value"
        value={fieldValue}
        readOnly
        autoComplete="off"
        data-attachment-hidden-input="true"
      />
      {
        !readOnlyMode ? (
          <AttachmentDropzone
            key={`attachments_${field.key}`}
            fieldKey={field.key}
            fieldValue={fieldValue}
            helpText={helpText}
            properties={field.properties}
            onChangeCount={handlerChangeAttachmentCount}
            onRemoveValue={handlerUpdateAttachmentValueAndCount}
            isValid={!showErrorMessage}
            errorMessage={validationMessage}
            isPDFDocument={false}
            field={field}
            useType={FORM_TYPE}
          />
        ) : (
          <AttachmentsList
            readOnlyMode
            inlineView
            fieldValue={fieldValue}
            fieldKey={fieldKey}
          />
        )
      }
    </>
  );

  const renderFieldCondition = (
    fieldType: string = '',
    selectFieldType: SelectFieldOptionsType | undefined = undefined,
  ) => {
    switch (fieldType) {
      case TEXT_FIELD:
        return renderTextField();
      case DATE_FIELD:
        return renderDateField();
      case TEXTAREA_FIELD:
        return renderTextArea();
      case SELECT_FIELD:
        if (selectFieldType && getIsRadioOrCheckbox(selectFieldType)) {
          return createSelectRadioField(selectFieldType);
        }
        return createSelectField();
      case SIGNING_FIELD:
        return renderSigningField();
      case ATTACHMENT_FIELD:
        return renderAttachmentField();
      case QUESTION_FIELD:
        return renderQuestionField();
      default:
        return renderTextField();
    }
  };

  const isActive = selectedField === fieldKey;
  const assignmentColor = useAssignmentFieldColor(
    field.assignment ?? SignerType.RECIPIENT,
    readOnlyMode,
  );

  return field && (
    <>
      <div
        className={cn(
          'd-flex align-items-center',
          {
            'col-12 label-text-field-editable': styledLabelColors,
            'form-section__field-label': !styledLabelColors,
          },
          getSelectedFieldClass([Number(selectedField)], fieldKey),
        )}
        style={{ color: assignmentColor }}
        onClick={clickFieldHandler}
        data-active={isActive}
        role="button"
        tabIndex={-1}
      >
        <div className={`form-section__field-input col-12 ${field.textFieldMask === TEXT_FIELD_MASKS.HIDDEN ? 'hidden-field-in-pdf' : ''}`}>
          <FieldName name={name} isRequired={field.requiredField || false} />
          {renderFieldCondition(field.type, field.selectFieldType)}
          {validateField && <FormFeedback>{validationMessage}</FormFeedback>}
        </div>
        {showButtons && !readOnlyMode && (
          <FormFieldButtons
            fieldKey={fieldKey}
            element={field as Node}
            existingFieldMask={existingFieldMask}
            hideValue={hideValue}
            setHideValue={setHideValue}
          />
        )}
      </div>
      {
        field.type === SIGNING_FIELD
        && (
          <SigningModal
            fieldKey={fieldKey}
            existingValue={fieldValue}
            currentTab={signatureTab}
            showModal={showSigningModal}
            onSave={handleSignatureSave}
            onCloseModal={setShowSigningModal}
            assignment={field.assignment}
            signatureFieldVariation={field.signatureFieldVariation}
          />
        )
      }
    </>
  );
};

export default EditorFieldView;