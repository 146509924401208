import { DOCUMENT_TYPE } from 'constants/general';
import ROUTES from 'constants/routes';
import { SignerFlow } from 'constants/signNow';
import {
  CLEAR_STORE,
  SEND_DOCUMENTS,
  SET_CARBON_COPY_USERS_INFO,
  SET_EMAIL_INFO,
  SET_LINK_VIEW_MODE,
  SET_ONLY_COLLECTION,
  SET_SEND_DOCUMENTS,
  SET_SIGNER_FLOW,
  SET_SIGNERS,
} from 'store/actions/actionTypes';
import { DocumentTypesType } from 'types/Documents';
import { SendDocumentInfoType, SignersInfoType } from 'types/SendDocuments';

export const clearStore = () => ({
  type: CLEAR_STORE,
});

export const setSendDocumentsInfo = (
  documentsInfo: SendDocumentInfoType[],
  initialLocation: string | null = ROUTES.DASHBOARD,
  resourceType: DocumentTypesType = DOCUMENT_TYPE,
  isDocumentCompleted: boolean = false,
) => ({
  type: SET_SEND_DOCUMENTS,
  payload: {
    documentsInfo,
    initialLocation,
    resourceType,
    isDocumentCompleted,
  },
});

export const setOnlyCollection = (collection: SendDocumentInfoType) => ({
  type: SET_ONLY_COLLECTION,
  payload: collection,
});

export const setSigners = (signersArray: SignersInfoType[]) => ({
  type: SET_SIGNERS,
  payload: signersArray,
});

export const setEmailInfo = (target: string, value: string) => ({
  type: SET_EMAIL_INFO,
  payload: { target, value },
});

export const sendDocumentsViaEmail = () => ({
  type: SEND_DOCUMENTS,
});

export const setLinkViewMode = (viewMode: string) => ({
  type: SET_LINK_VIEW_MODE,
  payload: viewMode,
});

export const setCarbonCopyUsersAction = (carbonCopyUsers: SignersInfoType[]) => ({
  type: SET_CARBON_COPY_USERS_INFO,
  payload: carbonCopyUsers,
});

export const setSignerFlow = (signingOrderFlow: SignerFlow) => ({
  type: SET_SIGNER_FLOW,
  payload: signingOrderFlow,
});