import { OrganizationInfoType } from 'types/AccountSettings';
import { IErrorField } from 'types/validation';

export const SUBSCRIPTION_TYPE_TRIAL = 'trialing';
export const TRIAL_ACCOUNT_LABEL = 'Trial Account';
export const MAX_TEMPLATES_COUNT_RESTRICTION = 'You can\'t create more templates in a free account';
export const MAX_TEMPLATES_FOR_FREE_TIER = 10;
export const REGISTER_FREE_TIER_ACCOUNT_LINK = 'https://www.unicornforms.com/sign-up';
export const UNICORNFORMS_BILLING_LINK = 'https://billing.unicornforms.com';

export const UNITED_STATES_COUNTRY_ID = '233';
export const TEXAS_STATE_ID = 1407;
export const HOUSTON_CITY_ID = 118699;

export const INITIAL_FORM_ERRORS: IErrorField = {
  name: null,
  address: null,
  postal_code: null,
  country: null,
  state_name: null,
  city: null,
};

export const DEFAULT_ORGANIZATION_INFO: OrganizationInfoType = {
  name: '',
  address: '',
  postal_code: '',
  country: '',
  state_name: '',
  city: '',
};

export const ORGANIZATION_INFO_FIELDS_NAMES = {
  NAME: 'name',
  COUNTRY: 'country',
  STATE_NAME: 'state_name',
  CITY: 'city',
  ADDRESS: 'address',
  POSTAL_CODE: 'postal_code',
} as const;